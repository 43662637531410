import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { SCORABLE_QUEUE_TYPES } from "@/game-lol/constants/constants.mjs";
import { findPlayer } from "@/game-lol/utils/match-utils.mjs";
import PlayerScores from "@/game-lol/utils/player-scores.mjs";
import { useGetTagsData } from "@/game-lol/utils/use-postmatch-data.mjs";
import {
  getDerivedId,
  getStaticData,
  isSameAccount,
} from "@/game-lol/utils/util.mjs";
import Badge, { BadgeList } from "@/shared/PostMatchBadge.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Badges = ({ region, name, matchId, size, amountShown = 5, truncate }) => {
  const state = useSnapshot(readState);

  const account = state.lol?.profiles?.[getDerivedId(region, name)];
  const match = state.lol?.matches?.[`${region?.toUpperCase()}_${matchId}`];
  const timeline = state.lol?.matchTimeline?.[matchId];

  const champions = getStaticData("champions");

  const currentParticipant = findPlayer(
    { ...account, summonerName: name },
    match,
  );

  const isScorable = SCORABLE_QUEUE_TYPES.includes(match?.queueId);
  const playersRankings = isScorable ? PlayerScores(match) : null;
  const isMVP = playersRankings
    ? isSameAccount(currentParticipant, playersRankings[0])
    : false;

  const tags = useGetTagsData({
    match,
    account,
    timeline,
    champions,
    isMVP,
  });
  const amountTruncated = tags.length - amountShown;

  if (!match || !tags.length || !region || !name) return null;

  return (
    <BadgeList data-size={size}>
      {tags.slice(0, amountShown).map((accolade) => (
        <Badge key={accolade.title} {...accolade} size={size} />
      ))}
      {truncate && amountTruncated > 0 && (
        <Badge
          style={{ marginInlineStart: "-0.35rem " }}
          title={`+${amountTruncated}`}
          size={size}
        />
      )}
    </BadgeList>
  );
};

export default Badges;
